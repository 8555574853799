import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notification-subscription-inputs"
export default class extends Controller {
  static targets = [
    "groupActivityInput", // Single toggle switch for group activity
    "groupNotificationSubscriptionInput", // Toggle switch for each group subscription
    "noticeContainer", // Container for notice messages
  ];

  groupActivityInputTargetConnected(element) {
    this.updateGroupNotificationSubscriptionInputs();
    element.addEventListener("change", this.updateGroupNotificationSubscriptionInputs);
  }

  groupActivityInputTargetDisconnected(element) {
    element.removeEventListener("change", this.updateGroupNotificationSubscriptionInputs);
  }

  groupNotificationSubscriptionInputTargetConnected(element) {
    element.disabled = !this.groupActivityInputTarget.checked;
  }

  updateGroupNotificationSubscriptionInputs = () => {
    this.groupNotificationSubscriptionInputTargets.forEach((input) => {
      input.disabled = !this.groupActivityInputTarget.checked;
    });

    if (this.groupActivityInputTarget.checked) {
      this.noticeContainerTarget.innerHTML = "";
    } else {
      this.noticeContainerTarget.innerHTML =
        "<p class='error'>You must enable group activity notifications to subscribe to groups.</p>";
    }
  };
}
